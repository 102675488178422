import React from "react";
import { Divider, Grid, Typography } from "@mui/material";

const MapTab = ({name}) => {
  return (
    <React.Fragment>
      <Grid container justifyContent="center">
        <Grid item style={{ padding: "5px 0px 5px 0px" }}>
          <Typography style={{ fontSize: 9.5, color: "#B4B4B4", fontWeight: "bold" }}>{name}</Typography>
        </Grid>
      </Grid>
      <Divider style={{ width: "100%", border: "solid 2px #B4B4B4" }} />
    </React.Fragment>
  );
}
 
export default MapTab;