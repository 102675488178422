import React from "react";
import { Divider, Grid, Typography } from "@mui/material";

const MapTabSelected = ({name}) => {
  return (
    <React.Fragment>
      <Grid container justifyContent="center">
        <Grid item style={{ padding: "5px 0px 5px 0px" }}>
          <Typography style={{ fontSize: 9.5, color: "#1D57B8", fontWeight: "bold" }}>{name}</Typography>
        </Grid>
      </Grid>
      <Divider style={{ width: "100%", border: "solid 2px #1D57B8" }} />
    </React.Fragment>
  );
}
 
export default MapTabSelected;