import React, { useEffect, useState } from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import { useStyles } from "./style";
import { connect, useDispatch, useSelector } from "react-redux";
import { MainActions } from '../../redux/actions/MainActions';
import * as gen_func from '../../helpers/general_functions';
import Spinner from "../Common/Spinner";
import Footer from "../Common/Footer";

const Areas = () => {

  /**variables */
  const dispatch = useDispatch();
  const classes = useStyles();
  const [area_data,set_area_data] = useState([]);
  const areas = useSelector(state => { return state.AreasReducer.data })

  /**Primera carga de la pagina */
  useEffect(() => {
    var faena_id = JSON.stringify(JSON.parse(localStorage.getItem('faena_id')))
    var pais_id = JSON.stringify(JSON.parse(localStorage.getItem('pais_id')))
    var operador_id = JSON.stringify(JSON.parse(localStorage.getItem('operador_id')))
    dispatch(MainActions.getAreas(`{'pais_id': ${pais_id}, 'operador_id': ${operador_id}, 'id': ${faena_id}}`))
  },[])

  /**Areas */
  useEffect(() => {
    if (areas.length !== 0) {
      set_area_data(areas)
    }
  },[areas])

  /**
   * Renderiza los botones de seleccion de Area
   * @returns Componente Boton de Area
   */
  const render_areas = () => {
    return area_data.map((area) => {
      return <Button className={classes.area_button} onClick={() => move_to_area(area.id_area)}>{gen_func.format_area_text(area.name_area)}</Button>
    })
  }

  /**
   * Te envia a la pagina del area seleccionada
   * @param {String} selected_area 
   */
  const move_to_area = (selected_area) => {
    window.location.href = `/zonas?sel_area=${selected_area}`
  }

  return (
    <React.Fragment>
      <Grid container direction="column" style={{ minHeight: "100vh", backgroundColor: "#0E56B8" }}>
        <Grid item xs={12} style={{ flexGrow: 1, textAlign: "center", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box>
            <Typography style={{ color: "#FFFFFF", fontSize: 30 }}>
              Seleccione una instalación
            </Typography>
            <Box sx={{ display: "inline" }}>
              {area_data.length !== 0 ? (
                render_areas()
              ) : (
                <Button>
                  <Spinner color='#fff' size="80" fontSize={15} />
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Footer />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
 
export default Areas;