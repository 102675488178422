import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import TiltPhaseSix from './TiltPhaseSix';
import Mantencion from '../../../../assets/img/mantencion/Mantención.svg';
import BG from '../../../../assets/img/mantencion/sincerely-media-gPnHi8AmO5k-unsplash.jpg';
import BG2 from '../../../../assets/img/mantencion/bg_2.png';
import MainBar from "../../MainBar";
import Footer from "../../Footer";

const EnMantencion = () => {

  const options = {
    max: 50,
    perspective: 1000,
    scale: 1.1,
  }

  useEffect(() => {
    document.body.style.overflow = "hidden"
  },[])

  return (
    <React.Fragment>
      <MainBar />
      <Box style={{ textAlign: "center", width: "100vw", height: "100vh", backgroundSize: "100%", backgroundRepeat: "no-repeat" }}>
        <TiltPhaseSix
          options={{
            max: 10,
            scale: 1.05
          }}
          style={{
            background: `url(${BG2}) no-repeat fixed center`,
            backgroundSize: '40%',
            height: "85vh",
            width: "100vw",
          }}
        >
          <TiltPhaseSix options={options}>
            <img src={Mantencion} alt="" style={{ height: "80vh", marginTop: "20px" }} />
          </TiltPhaseSix>
        </TiltPhaseSix>
      </Box>
      <Footer />
    </React.Fragment>
  );
}
 
export default EnMantencion;