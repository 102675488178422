import React from "react";
import { Box, Typography, Button, AppBar, Toolbar } from "@mui/material";
//Imagenes
import MainIcon from '../../assets/img/main_icon.png';

const MainBar = () => {

  /**Log Out */
  const log_out = () => {
    localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_ACCESS_TOKEN)
    window.location.href = "/"
  }

  const take_me_to_home = () => {
    window.location.href = "/"
  }

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" elevation="0" style={{ backgroundColor: "#1D57B8", height: "50px" }}>
          <Toolbar>
            <img onClick={take_me_to_home} src={MainIcon} style={{ width: "140px", marginTop: "-15px", cursor: 'pointer' }}/>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, marginLeft: "10px!important", fontWeight: "bold!important", marginTop: "-15px!important" }}>
              Monitoreo Remoto de Espesores
            </Typography>
            <Button onClick={log_out} style={{ backgroundColor: "#356FD1", color: "#fff", textTransform: 'none', fontWeight: "bold", fontSize: 12, marginTop: "-15px", zIndex: 999 }}>Salir</Button>
          </Toolbar>
        </AppBar>
      </Box>
    </React.Fragment>
  );
}
 
export default MainBar;