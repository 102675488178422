import React, { useEffect, useRef, useState } from "react";
import { Grid, Box, Tabs, Tab, Divider, Typography } from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import MainBar from "../Common/MainBar";
import SecondBar from "../Common/SecondBar";
import MapTab from "../Common/Tabs/MapTab";
import MapTabSelected from "../Common/Tabs/MapTabSelected";
import Sensor from "./Sensor";
import * as gen_func from '../../helpers/general_functions';
import { MainActions } from "../../redux/actions/MainActions";
import Spinner from "../Common/Spinner";
import Footer from "../Common/Footer";
//Icon
import SensorsIcon from '@mui/icons-material/Sensors';

const Sensores = () => {

  /**variables */
  const panel = useRef();
  const image_panel = useRef();
  const dispatch = useDispatch();
  const [real_width,set_real_width] = useState(0);
  const [real_height,set_real_height] = useState(0);
  const [main_width,set_main_width] = useState(0);
  const [main_height,set_main_height] = useState(0);
  const [resize,set_resize] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const selected_area = queryParameters.get("sel_area");
  const selected_zona = queryParameters.get("sel_zona");
  const [zona_data,set_zona_data] = useState([]);
  const [sensor_data,set_sensor_data] = useState([]);
  const [value, setValue] = useState(0);
  const [cur_area,set_cur_area] = useState(selected_area);
  const [cur_zona,set_cur_zona] = useState(selected_zona);
  const [selected_sensors,set_selected_sensors] = useState([]);
  const [sensor_hover,set_sensor_hover] = useState(0);
  const [selected_color,set_selected_color] = useState({"r":69,"g":172,"b":255});
  const areas = useSelector(state => { return state.AreasReducer.data })
  const zonas = useSelector(state => { return state.ZonasReducer.data })
  const sensores = useSelector(state => { return state.SensoresReducer.data })

  /**Primera carga */
  useEffect(() => {
    var faena_id = JSON.stringify(JSON.parse(localStorage.getItem('faena_id')))
    var pais_id = JSON.stringify(JSON.parse(localStorage.getItem('pais_id')))
    var operador_id = JSON.stringify(JSON.parse(localStorage.getItem('operador_id')))
    dispatch(MainActions.getAreas(`{'pais_id': ${pais_id}, 'operador_id': ${operador_id}, 'id': ${faena_id}}`))
    dispatch(MainActions.getZonas(selected_area))
    dispatch(MainActions.getSensores(selected_zona))
    document.addEventListener('click', function(evt) {
      if (!evt.target.id.includes("sensor_")) {
        set_selected_sensors([])
      }
    })
    window.addEventListener('resize', function(evt) {
      set_resize(true)
    })
    window.addEventListener("resize",gen_func.debounce(function(e){
      this.window.location.reload()
      set_resize(false)
    }))
  },[])

  /**Carga de areas */
  useEffect(() => {
    if (areas.length !== 0) {
      areas.forEach((area,index) => {
        if (area.id_area === parseInt(selected_area)) {
          set_cur_area(gen_func.format_area_text(area.name_area))
        }
      })
    }
  },[areas])

  /**Carga de zonas */
  useEffect(() => {
    if (zonas.length !== 0) {
      set_zona_data(zonas.data_zones)
      zonas.data_zones.forEach((zona,index) => {
        if (parseInt(zona.id_zone) === parseInt(selected_zona)) {
          if (zona.markers_color) {
            set_selected_color(zona.markers_color)
          }
          setValue(index)
          set_cur_zona(zona.name_zone)
        }
      })
    }
  },[zonas])

  /**Carga de sensores */
  useEffect(() => {
    if (sensores.length !== 0) {
      set_sensor_data(sensores.data_devices)
    }
  },[sensores])

  /**Controla la distribucion de los sensores */
  const get_image_dimensions = () => {
    const img = new Image();
    img.onload = function() {
      let width = parseInt((image_panel.current.clientHeight*this.width)/this.height)
      let height = image_panel.current.clientHeight
      set_main_width(width)
      set_main_height(height)
      set_real_width(this.width)
      set_real_height(this.height)
    }
    img.src = `https://${process.env.REACT_APP_STOGARE_ACCOUNT}.blob.core.windows.net/${process.env.REACT_APP_CONTAINER_ZONAS}/${sensores.data_zone.img_file_name}`
  }

  /**
   * Detecta los sensores y rellena la lista de sensores seleccionado hasta 2 sensores a la vez
   * @param {String} sensor Nombre del sensor
   */
  const on_click_sensor = (sensor) => {
    if (selected_sensors.length < 2) {
      const s = []
      selected_sensors.forEach(sense => {
        if (sense !== sensor) {
          s.push(sense)
        }
      })
      s.push(sensor)
      set_selected_sensors(s)
    }
  }

  /**
   * Controla el hover del sensor
   * @param {Int} id 
   */
  const on_hover_sensor = (id) => {
    set_sensor_hover(id)
  }

  /**Controla la salida del hover del sensor */
  const on_hover_leave_sensor = () => {
    set_sensor_hover(0)
  }

  /**
   * Determina si el sensro esta o no seleccionado entre los dos permitidos
   * @param {String} sensor 
   * @returns true o false
   */
  const determine_selected_sensor = (sensor) => {
    let paint = false
    selected_sensors.forEach(element => {
      if (element === sensor) {
        paint = true
      }
    })
    return paint
  }

  /**
   * Determian el valor del height de la tabla segun los sensores seleccionados
   * @returns height value
   */
  const determine_table_height = () => {
    switch (selected_sensors.length) {
      case 0:
        return "46vh"
        break;
      case 1:
        return "42.4vh"
        break;
      case 2:
        return "39vh"
        break;
    }
  }
 
  /**
   * Controla el diseño de los Tabs
   * @param {Object} props 
   * @returns Componente Tab Independiente
   */
  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && (<Box sx={{ backgroundColor: "#F8F8F8", padding: "1px 8px 8px 8px" }}>{children}</Box>)}
      </div>
    );
  }

  /**
   * Controla el cambio de los Tabs
   * @param {Object} event 
   * @param {String} newValue 
   */
  const handleChange = (event, newValue) => {
    set_cur_zona(zona_data[newValue].name_zone)
    dispatch(MainActions.getSensores(zona_data[newValue].id_zone))
    if (zona_data[newValue].markers_color) {
      set_selected_color(zona_data[newValue].markers_color)
    }
    setValue(newValue) 
  }

  /**
   * Controla el Tab que se selecciona
   * @param {Int} index 
   * @returns El Tab seleccionado
   */
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  /**
   * Renderiza los componentes dependiendo de las zonas existentes
   * @returns Componente Tab
   */
  const render_tabs = () => {
    return zona_data.map((zonas,index) => {
      return <Tab style={{ minHeight: "30px", height: "30px"  }} label={value === index ? (<MapTabSelected name={zonas.name_zone} />):(<MapTab name={zonas.name_zone} />)} {...a11yProps(index)}/>
    })
  }

  /**
   * Renderiza los mapas de las zonas
   * @returns Componente CustomTab
   */
  const render_tab_panels = () => {
    return zona_data.map((zonas,index) => {
      get_image_dimensions()
      return (
        <CustomTabPanel value={value} index={index}>
          <img ref={image_panel} src={`https://${process.env.REACT_APP_STOGARE_ACCOUNT}.blob.core.windows.net/${process.env.REACT_APP_CONTAINER_ZONAS}/${sensores.data_zone.img_file_name}`} style={{ height: "74.8vh" }} />
          {render_sensors()}
        </CustomTabPanel>
      )
    })
  }

  /**
   * Renderiza los sensores de una zona
   * @returns Componente Sensores
   */
  const render_sensors = () => {
    return sensor_data.map((sens) => {
      return sens.coords.map((s) => {

        let t = s.top//top base de datos
        let l = s.left//left base de datos
        let top_plus = 150//pixeles barras superiores
        
        let top = Math.round(((t*main_height)/real_height)+(top_plus-30))//top base de datos * height de imagen en pantalla / height imagen real + top_plus + tabs
        let left = Math.round(((l*main_width)/real_width)+20)//left base de datos * width de imagen en pantalla / height imagen real + padding lateral

        let hovered = sensor_hover === sens.monitoring_point_descriptor ? true : false

        return(
          <div onClick={() => on_click_sensor(sens.monitoring_point_descriptor)} onMouseEnter={() => on_hover_sensor(sens.monitoring_point_descriptor)} onMouseLeave={() => on_hover_leave_sensor()}>
            <Sensor top={top} left={left} name={sens.monitoring_point_descriptor} selected_sensor={ determine_selected_sensor(sens.monitoring_point_descriptor) ? true : false} hovered={hovered} color={selected_color} />
          </div>
        )
      })
    })
  }

  /**
   * Renderiza los datos para la tabla
   * @returns Componente Datos Tabla
   */
  const render_tabla_sensores = () => {
    let count = 0
    let color = `rgba(${selected_color.r},${selected_color.g},${selected_color.b},1)`
    return sensor_data.map((sens,index) => {
      count = count +1
      if (!determine_selected_sensor(sens.monitoring_point_descriptor)) {
        let hovered = sensor_hover === sens.monitoring_point_descriptor ? true : false
        return(
          <Grid item xs={12} 
            id={`sensor_${sens.monitoring_point_descriptor}`}
            style={{ justifyContent: 'center', alignContent: 'center', width: "100%", display: "flex", cursor: "pointer" }} 
            onMouseEnter={() => on_hover_sensor(sens.monitoring_point_descriptor)} 
            onMouseLeave={() => on_hover_leave_sensor()}
            onClick={() => {
              on_click_sensor(sens.monitoring_point_descriptor)
            }}
          >
            <Box id={`sensor_${sens.monitoring_point_descriptor}`} style={{ width: "10%", textAlign: "center", padding: "5px 0px 5px 0px", alignContent: "center", backgroundColor: hovered ? "#7FCDFD" : color, margin: "1px 1px 1px 0px", borderRadius: index+1 < parseInt(sensor_data.length) ? "0px" : "0px 0px 0px 5px"  }}>
              <Typography id={`sensor_${sens.monitoring_point_descriptor}`} style={{ color: "#fff", fontWeight: "bold", fontSize: 14 }}>{sens.monitoring_point_descriptor}</Typography>
            </Box>
            <Box id={`sensor_${sens.monitoring_point_descriptor}`} style={{ width: "20%", textAlign: "center", padding: "5px 2px 5px 2px", backgroundColor: hovered ? "#D3D3D3" : gen_func.isEven(count) ? "#F5F5F5" : "#E5E5E5", alignContent: "center", margin: "1px" }}>
              <Typography id={`sensor_${sens.monitoring_point_descriptor}`} style={{ color: "#727272", fontSize: 12 }}>{gen_func.check_if_NaN(parseFloat(sens.thickness).toFixed(2)+" mm")}</Typography>
            </Box>
            <Box id={`sensor_${sens.monitoring_point_descriptor}`} style={{ width: "20%", textAlign: "center", padding: "5px 2px 5px 2px", backgroundColor: hovered ? "#D3D3D3" : gen_func.isEven(count) ? "#F5F5F5" : "#E5E5E5", alignContent: "center", margin: "1px" }}>
              <Typography id={`sensor_${sens.monitoring_point_descriptor}`} style={{ color: "#727272", fontSize: 12 }}>{gen_func.format_date(sens.last_result_datetime)}</Typography>
            </Box>
            <Box id={`sensor_${sens.monitoring_point_descriptor}`} style={{ width: "20%", textAlign: "center", padding: "5px 2px 5px 2px", backgroundColor: hovered ? "#D3D3D3" : gen_func.isEven(count) ? "#F5F5F5" : "#E5E5E5", alignContent: "center", margin: "1px" }}>
              <Typography id={`sensor_${sens.monitoring_point_descriptor}`} style={{ color: "#727272", fontSize: 12 }}>{gen_func.check_if_NaN(parseFloat(sens.wear_rate*30).toFixed(3)+" mm/mes")}</Typography>
            </Box>
            <Box id={`sensor_${sens.monitoring_point_descriptor}`} style={{ width: "20%", textAlign: "center", padding: "5px 2px 5px 2px", backgroundColor: hovered ? "#D3D3D3" : gen_func.isEven(count) ? "#F5F5F5" : "#E5E5E5", alignContent: "center", margin: "1px" }}>
              <Typography id={`sensor_${sens.monitoring_point_descriptor}`} style={{ color: "#727272", fontSize: 12 }}>{gen_func.check_if_NaN(sens.nominal_thickness+" mm")}</Typography>
            </Box>
            <Box id={`sensor_${sens.monitoring_point_descriptor}`} style={{ width: "20%", textAlign: "center", padding: "5px 2px 5px 2px", backgroundColor: hovered ? "#D3D3D3" : gen_func.isEven(count) ? "#F5F5F5" : "#E5E5E5", alignContent: "center", margin: "1px" }}>
              {render_status(sens.battery_status)}
            </Box>
            <Box id={`sensor_${sens.monitoring_point_descriptor}`} style={{ width: "20%", textAlign: "center", padding: "5px 2px 5px 2px", backgroundColor: hovered ? "#D3D3D3" : gen_func.isEven(count) ? "#F5F5F5" : "#E5E5E5", alignContent: "center", margin: "1px 0px 1px 1px" }}>
              <Typography id={`sensor_${sens.monitoring_point_descriptor}`} style={{ color: "#727272", fontSize: 12 }}><b>{sens.availability}</b> %</Typography>
            </Box>
          </Grid>
        )
      }
    })
  }

  /**
   * Renderiza los sensores seleccionados
   * @returns Componente sensores seleccionados
   */
  const render_selected_sensores = () => {
    if (selected_sensors.length !== 0) {
      let count = 0
      return selected_sensors.map((sensor) => {
        count = count +1
        return (
          <Grid item xs={12} style={{ justifyContent: 'center', alignContent: 'center', width: "100%", display: "flex" }}>
            <Box style={{ width: "10%", textAlign: "center", padding: "5px 0px 5px 0px", alignContent: "center", backgroundColor: "#F7451C", margin: "1px 1px 1px 0px" }}>
              <Typography style={{ color: "#fff", fontWeight: "bold", fontSize: 14 }}>{get_sensor_value('monitoring_point_descriptor',sensor)}</Typography>
            </Box>
            <Box style={{ width: "20%", textAlign: "center", padding: "5px 2px 5px 2px", backgroundColor: gen_func.isEven(count) ? "#F5F5F5" : "#E5E5E5", alignContent: "center", margin: "1px" }}>
              <Typography style={{ color: "#727272", fontSize: 12 }}>{gen_func.check_if_NaN(parseFloat(get_sensor_value('thickness',sensor)).toFixed(2)+" mm")}</Typography>
            </Box>
            <Box style={{ width: "20%", textAlign: "center", padding: "5px 2px 5px 2px", backgroundColor: gen_func.isEven(count) ? "#F5F5F5" : "#E5E5E5", alignContent: "center", margin: "1px" }}>
              <Typography style={{ color: "#727272", fontSize: 12 }}>{gen_func.check_if_NaN(gen_func.format_date(get_sensor_value('last_result_datetime',sensor)))}</Typography>
            </Box>
            <Box style={{ width: "20%", textAlign: "center", padding: "5px 2px 5px 2px", backgroundColor: gen_func.isEven(count) ? "#F5F5F5" : "#E5E5E5", alignContent: "center", margin: "1px" }}>
              <Typography style={{ color: "#727272", fontSize: 12 }}>{gen_func.check_if_NaN(parseFloat(get_sensor_value('wear_rate',sensor)*30).toFixed(3)+" mm/mes")}</Typography>
            </Box>
            <Box style={{ width: "20%", textAlign: "center", padding: "5px 2px 5px 2px", backgroundColor: gen_func.isEven(count) ? "#F5F5F5" : "#E5E5E5", alignContent: "center", margin: "1px" }}>
              <Typography style={{ color: "#727272", fontSize: 12 }}>{gen_func.check_if_NaN(get_sensor_value('nominal_thickness',sensor)+" mm")}</Typography>
            </Box>
            <Box style={{ width: "20%", textAlign: "center", padding: "5px 2px 5px 2px", backgroundColor: gen_func.isEven(count) ? "#F5F5F5" : "#E5E5E5", alignContent: "center", margin: "1px" }}>
              {render_status(get_sensor_value('battery_status',sensor))}
            </Box>
            <Box style={{ width: "20%", textAlign: "center", padding: "5px 2px 5px 2px", backgroundColor: gen_func.isEven(count) ? "#F5F5F5" : "#E5E5E5", alignContent: "center", margin: "1px 0px 1px 1px"  }}>
              <Typography style={{ color: "#727272", fontSize: 12 }}><b>{get_sensor_value('availability',sensor)}</b> %</Typography>
            </Box>
          </Grid>
        )
      })
    }
  }

  /**
   * Renderiza el estado del sensor
   * @param {String} status 
   * @returns Retorna el estado del sensor con un semaforo
   */
  const render_status = (status) => {
    switch (status) {
      case "normal":
        return(
          <Grid container>
            <Grid item xs={1} style={{ paddingLeft: "10px" }}>
              <Box style={{ width: "15px", height: "15px", backgroundColor: "#3DC455", borderRadius: "100px" }}></Box>
            </Grid>
            <Grid item xs={8} style={{ marginTop: "-1px" }}>
              <Typography style={{ color: "#727272", fontSize: 12 }}>Normal</Typography>
            </Grid>
          </Grid>
        )
        break;
      case "low":
        return(
          <Grid container>
            <Grid item xs={1} style={{ paddingLeft: "10px" }}>
              <Box style={{ width: "15px", height: "15px", backgroundColor: "#F8A900", borderRadius: "100px" }}></Box>
            </Grid>
            <Grid item xs={8} style={{ marginTop: "-1px" }}>
              <Typography style={{ color: "#727272", fontSize: 12 }}>Bajo</Typography>
            </Grid>
          </Grid>
        )
        break;
      case "critical":
        return(
          <Grid container>
            <Grid item xs={1} style={{ paddingLeft: "10px" }}>
              <Box style={{ width: "15px", height: "15px", backgroundColor: "#E10000", borderRadius: "100px" }}></Box>
            </Grid>
            <Grid item xs={8} style={{ marginTop: "-1px" }}>
              <Typography style={{ color: "#727272", fontSize: 12 }}>Critico</Typography>
            </Grid>
          </Grid>
        )
        break;
      case "unknow":
        return(
          <Grid container>
            <Grid item xs={1} style={{ paddingLeft: "10px" }}>
              <Box style={{ width: "15px", height: "15px", backgroundColor: "#828282", borderRadius: "100px" }}></Box>
            </Grid>
            <Grid item xs={8} style={{ marginTop: "-1px" }}>
              <Typography style={{ color: "#727272", fontSize: 12 }}>-</Typography>
            </Grid>
          </Grid>
        )
        break;
    }
  }

  /**
   * Obtiene el dato especifico del sensor
   * @param {String} dato 
   * @param {Int} id_sensor 
   * @returns Retorna el dato especificado
   */
  const get_sensor_value = (dato,id_sensor) => {
    var d = ''
    sensores.data_devices.forEach(element => {
      if (element.monitoring_point_descriptor === id_sensor) {
        d = element[dato]
      }
    })
    return d
  }

  return (
    <React.Fragment>
      <Grid container direction="column" style={{ minHeight: "100vh" }}>

        <Grid item style={{ flexGrow: 0 }}>
          <MainBar />
          <SecondBar selected_area={cur_area} selected_zona={cur_zona} return_to={`/zonas?sel_area=${selected_area}`} />
        </Grid>

        <Grid item container style={{ flexGrow: 1 }}>
        { resize ? (
          <Grid item xs={12} style={{ textAlign: "center", padding: "40px" }}>
            <Spinner color="#2C58BB" size="200" fontSize={30}/>
          </Grid>
        ):(
          <>
          { sensores.length !== 0 ? (
            <Grid item xs={12}>
              <Grid container>

                {/**MAPA */}
                <Grid item xl={5} lg={5} md={12} sm={12} xs={12} style={{ padding: "15px 8px 15px 15px" }}>
                  <Box ref={panel} style={{ backgroundColor: "#F8F8F8", height: "74.8vh" }}>
                    <Tabs value={value} onChange={handleChange} style={{ minHeight: "30px", height: "30px" }} TabIndicatorProps={{ style: { display: "none" } }} fullWidth={true} centered>
                      {render_tabs()}
                    </Tabs>
                    {render_tab_panels()}
                  </Box>
                </Grid>
                {/**MAPA */}

                <Grid item xl={7} lg={7} md={12} sm={12} xs={12} style={{ padding: "15px 15px 15px 8px" }}>
                  <Box style={{ backgroundColor: "#F8F8F8", height: "62.5vh", marginBottom: "15px" }}>
                    <Grid container style={{ padding: "10px" }}>

                      <Grid item xs={12} style={{ justifyContent: 'center', alignContent: 'center', width: "100%", display: "flex" }}>
                        <Box style={{ width: "10%", textAlign: "center", padding: "10px 0px 10px 0px", alignContent: "center", margin: "1px 1px 1px 0px", backgroundColor: "#0057BB", borderRadius: "5px 0px 0px 0px" }}>
                          <Typography style={{ color: "#fff", fontWeight: "bold", fontSize: 14 }}>Sensor</Typography>
                        </Box>
                        <Box style={{ width: "20%", textAlign: "center", padding: "10px 2px 10px 2px", margin: "1px", backgroundColor: "#0057BB"  }}>
                          <Typography style={{ color: "#fff", fontWeight: "bold", fontSize: 14 }}>Espesor</Typography>
                          <Typography style={{ color: "#FFFFFF80", fontSize: 13 }}>mm</Typography>
                        </Box>
                        <Box style={{ width: "20%", textAlign: "center", padding: "10px 2px 10px 2px", alignContent: "center", margin: "1px", backgroundColor: "#0057BB"  }}>
                          <Typography style={{ color: "#fff", fontWeight: "bold", fontSize: 14 }}>Útima actualización</Typography>
                        </Box>
                        <Box style={{ width: "20%", textAlign: "center", padding: "10px 2px 10px 2px", margin: "1px", backgroundColor: "#0057BB"  }}>
                          <Typography style={{ color: "#fff", fontWeight: "bold", fontSize: 14 }}>Tasa de desgaste</Typography>
                          <Typography style={{ color: "#FFFFFF80", fontSize: 13 }}>mm/mes</Typography>
                        </Box>
                        <Box style={{ width: "20%", textAlign: "center", padding: "10px 2px 10px 2px", margin: "1px", backgroundColor: "#0057BB"  }}>
                          <Typography style={{ color: "#fff", fontWeight: "bold", fontSize: 14 }}>Espesor nominal</Typography>
                          <Typography style={{ color: "#FFFFFF80", fontSize: 13 }}>mm</Typography>
                        </Box>
                        <Box style={{ width: "20%", textAlign: "center", padding: "10px 2px 10px 2px", alignContent: "center", margin: "1px", backgroundColor: "#0057BB"  }}>
                          <Typography style={{ color: "#fff", fontWeight: "bold", fontSize: 14 }}>Estado Bateria</Typography>
                        </Box>
                        <Box style={{ width: "20%", textAlign: "center", padding: "10px 2px 10px 2px", margin: "1px", backgroundColor: "#0057BB", borderRadius: "0px 5px 0px 0px"  }}>
                          <Typography style={{ color: "#fff", fontWeight: "bold", fontSize: 14 }}>Uptime</Typography>
                          <Typography style={{ color: "#FFFFFF80", fontSize: 13 }}>%</Typography>
                        </Box>
                      </Grid>

                      {render_selected_sensores()}
                      <Box style={{ overflowY: 'auto', height: determine_table_height(), width: "100%" }}>
                        {render_tabla_sensores()}
                      </Box>

                    </Grid>
                  </Box>
                  <Box style={{ backgroundColor: "#F8F8F8", marginTop: "15px", padding: "10px", height: "13.4vh" }}>
                    
                    <Grid container>

                      {/**ESPESORES */}
                      <Grid item style={{ width: "40%", padding: "0px 5px 0px 0px" }}>
                        <Box style={{ backgroundColor: "#fff" }}>
                          <Grid container style={{ padding: "2px 10px 3px 10px" }}>

                            <Grid item xs={12} style={{ justifyContent: 'center', alignContent: 'center', display: "flex", padding: "3px 0px 3px 0px" }}>
                              <Typography style={{ color: "#0057BB", fontWeight: "bold", fontSize: 15 }}>Espesor</Typography>
                            </Grid>

                            <Divider style={{ width: "100%" }} />

                            <Grid item xs={12} style={{ justifyContent: 'center', alignContent: 'center', width: "100%", display: "flex" }}>
                              <Box style={{ width: "30%", textAlign: "center", padding: "2px" }}>
                                <Typography style={{ fontWeight: "bold", fontSize: 12, color: "#5B5B5B" }}>Minimo</Typography>
                              </Box>
                              <Box style={{ width: "30%", textAlign: "center", padding: "2px" }}>
                                <Typography style={{ fontWeight: "bold", fontSize: 12, color: "#5B5B5B" }}>Promedio</Typography>
                              </Box>
                              <Box style={{ width: "30%", textAlign: "center", padding: "2px" }}>
                                <Typography style={{ fontWeight: "bold", fontSize: 12, color: "#5B5B5B" }}>Maximo</Typography>
                              </Box>
                            </Grid>

                            { zona_data.length !== 0 ? (
                              <Grid container>
                                <Grid item xs={4} style={{ padding: "5px", textAlign: "center" }}>
                                  <Box style={{ backgroundColor: "#F8F8F8", padding: "2px" }}>
                                    <Typography style={{ color: "#0057BB", fontWeight: "bold", fontSize: 15 }}>{gen_func.check_if_NaN(parseFloat(zona_data[value].thickness.min).toFixed(1)+" mm")}</Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={4} style={{ padding: "5px", textAlign: "center" }}>
                                  <Box style={{ backgroundColor: "#F8F8F8", padding: "2px" }}>
                                    <Typography style={{ color: "#0057BB", fontWeight: "bold", fontSize: 15 }}>{gen_func.check_if_NaN(parseFloat(zona_data[value].thickness.mean).toFixed(1)+" mm")}</Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={4} style={{ padding: "5px", textAlign: "center" }}>
                                  <Box style={{ backgroundColor: "#F8F8F8", padding: "2px" }}>
                                    <Typography style={{ color: "#0057BB", fontWeight: "bold", fontSize: 15 }}>{gen_func.check_if_NaN(parseFloat(zona_data[value].thickness.max).toFixed(1)+" mm")}</Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            ):(<>Loading...</>)}

                          </Grid>
                        </Box>
                      </Grid>
                      {/**ESPESORES */}

                      {/**TASA DESGASTE */}
                      <Grid item style={{ width: "40%", padding: "0px 5px 0px 5px" }}>
                        <Box style={{ backgroundColor: "#fff" }}>
                          <Grid container style={{ padding: "2px 10px 3px 10px" }}>

                            <Grid item xs={12} style={{ justifyContent: 'center', alignContent: 'center', display: "flex", padding: "3px 0px 3px 0px" }}>
                              <Typography style={{ color: "#0057BB", fontWeight: "bold", fontSize: 15 }}>Tasa de desgaste</Typography>
                            </Grid>

                            <Divider style={{ width: "100%" }} />

                            <Grid item xs={12} style={{ justifyContent: 'center', alignContent: 'center', width: "100%", display: "flex" }}>
                              <Box style={{ width: "30%", textAlign: "center", padding: "2px" }}>
                                <Typography style={{ fontWeight: "bold", fontSize: 12, color: "#5B5B5B" }}>Minimo</Typography>
                              </Box>
                              <Box style={{ width: "30%", textAlign: "center", padding: "2px" }}>
                                <Typography style={{ fontWeight: "bold", fontSize: 12, color: "#5B5B5B" }}>Promedio</Typography>
                              </Box>
                              <Box style={{ width: "30%", textAlign: "center", padding: "2px" }}>
                                <Typography style={{ fontWeight: "bold", fontSize: 12, color: "#5B5B5B" }}>Maximo</Typography>
                              </Box>
                            </Grid>

                            { zona_data.length !== 0 ? (
                              <Grid container>
                                <Grid item xs={4} style={{ padding: "5px", textAlign: "center" }}>
                                  <Box style={{ backgroundColor: "#F8F8F8", padding: "2px" }}>
                                    <Typography style={{ color: "#0057BB", fontWeight: "bold", fontSize: 15 }}>{gen_func.check_if_NaN(parseFloat(zona_data[value].wear_rate.min*30).toFixed(3)+" mm/mes")}</Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={4} style={{ padding: "5px", textAlign: "center" }}>
                                  <Box style={{ backgroundColor: "#F8F8F8", padding: "2px" }}>
                                    <Typography style={{ color: "#0057BB", fontWeight: "bold", fontSize: 15 }}>{gen_func.check_if_NaN(parseFloat(zona_data[value].wear_rate.mean*30).toFixed(3)+" mm/mes")}</Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={4} style={{ padding: "5px", textAlign: "center" }}>
                                  <Box style={{ backgroundColor: "#F8F8F8", padding: "2px" }}>
                                    <Typography style={{ color: "#0057BB", fontWeight: "bold", fontSize: 15 }}>{gen_func.check_if_NaN(parseFloat(zona_data[value].wear_rate.max*30).toFixed(3)+" mm/mes")}</Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            ):(<>Loading...</>)}

                          </Grid>
                        </Box>
                      </Grid>
                      {/**TASA DESGASTE */}

                      {/**BATERIA */}
                      <Grid item style={{ width: "20%", padding: "0px 5px 0px 5px" }}>
                        <Box style={{ backgroundColor: "#fff" }}>
                          <Grid container style={{ padding: "2px 10px 3px 10px" }}>

                            <Grid item xs={12} style={{ textAlign: 'center', justifyContent: 'center', alignContent: 'center', display: "flex", padding: "3px 0px 3px 0px" }}>
                              <Typography style={{ color: "#0057BB", fontWeight: "bold", fontSize: 15 }}>Uptime de zona</Typography>
                            </Grid>

                            <Grid item xs={12} style={{ justifyContent: 'center', alignContent: 'center', width: "100%", display: "flex" }}>
                              <Box style={{ width: "30%", textAlign: "center", padding: "2px", height: "20px" }}></Box>
                            </Grid>

                            { zona_data.length !== 0 ? (
                              <Grid container>
                                <Grid item xs={12} style={{ padding: "5px", textAlign: "center" }}>
                                  <Box style={{ backgroundColor: "#F8F8F8", padding: "2px" }}>
                                    <Typography style={{ color: "#0057BB", fontWeight: "bold", fontSize: 15 }}>{sensores.data_zone.availability}%</Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            ):(<>Loading...</>)}

                          </Grid>
                        </Box>
                      </Grid>
                      {/**BATERIA */}

                    </Grid>

                  </Box>
                </Grid>

              </Grid>
            </Grid>
          ):(
            <Grid item xs={12} style={{ textAlign: "center", padding: "40px" }}>
              <Spinner color="#2C58BB" size="200" fontSize={30}/>
            </Grid>
          )}
          </>
        )}
        </Grid>

        <Grid item style={{ flexGrow: 0 }}>
          <Footer />
        </Grid>

      </Grid>

    </React.Fragment>
  );
}
 
export default Sensores;