import CryptoJS from 'crypto-js';
import moment from 'moment';
const SECRET_KEY = 'mysecretkey';

/**
 * Determina si un numero es par
 * @param {Int} number 
 * @returns True or False
 */
function isEven (number) {
  return Math.floor(number / 2) * 2 === number;
}

/**
   * Controla si un evento termino de forma manual
   * @param {Object} func 
   * @returns Timer para determinar si el evento termino
   */
const debounce = (func) => {
  var timer;
  return function(event){
    if(timer) clearTimeout(timer);
    timer = setTimeout(func,100,event);
  };
}

/**
 * Recalcula el posicionamiento de los sensores u otros objetos segun los pixeles dispuestos en pantalla
 * @param {Int} real 
 * @param {Int} cur 
 * @param {Int} pos 
 * @returns 
 */
const recalculate_sensor_position = (real,cur,pos) => {
  return (pos*cur)/real
}

/**
   * Encripta datos
   * @param {String} name 
   * @param {String} data 
   */
const encryptData =(name,data)=> {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
  localStorage.setItem(name, encrypted);
}

/**
 * Da un espacio al texto de area
 * @param {String} text 
 * @returns Texto reformateado
 */
const format_area_text = (text) => {
  return text.replace('&nbsp;',' ')
}

/**
 * Da formato a una fecha
 * @param {String} date 
 * @returns Fecha reformateada
 */
const format_date = (date) => {
  var mydate = new Date(date);
  var d = moment(mydate.toDateString()).format('DD-MMM-YYYY')
  var resp = d.replace("-","/").replace("-","/")
  if (d === "Invalid date") {
    resp = '-'
  }
  return resp
}

/**
 * Detecta si el valor obtenido desde la tabla es NaN
 * @param {String} value 
 * @returns Valor filtrado
 */
const check_if_NaN = (value) => {
  var resp = '-'
  if (!value.includes('NaN')) {
    resp = value
  }
  return resp
}

export {
  isEven,
  debounce,
  recalculate_sensor_position,
  encryptData,
  format_area_text,
  format_date,
  check_if_NaN
}