import { MainConstants } from "../constants/MainConstants";
import { MainServices } from "../services/MainServices";

const success = ( type , data ) => {
  return { type, data }
}

const getAreas = (dict_user_access) => {
  return async dispatch => {
    try {
      const res = await MainServices.Areas(dict_user_access)
      if (res.status !== 200) throw new Error( res.message || 'Internal Server Error' )
      dispatch(success(MainConstants.AREAS_SUCCESS, res.data))
    } catch (error) {
      console.log(error)
    }
  }
}

const getZonas = (area_id) => {
  return async dispatch => {
    try {
      const res = await MainServices.Zonas(area_id)
      if (res.status !== 200) throw new Error( res.message || 'Internal Server Error' )
      dispatch(success(MainConstants.ZONAS_SUCCESS, res.data))
    } catch (error) {
      console.log(error)
    }
  }
}

const getSensores = (zona_id) => {
  return async dispatch => {
    try {
      const res = await MainServices.Sensores(zona_id)
      if (res.status !== 200) throw new Error( res.message || 'Internal Server Error' )
        dispatch(success(MainConstants.SENSORES_SUCCES, res.data))
    } catch (error) {
      console.log(error)
    }
  }
}

export const MainActions = {
  getAreas,
  getZonas,
  getSensores
}