import { combineReducers } from 'redux';
//REDUCERS
import { AreasReducer } from './AreasReducer';
import { ZonasReducer } from './ZonasReducer';
import { SensoresReducer } from './SensoresReducer';

export const createRootReducer = () =>
  combineReducers({
    AreasReducer,
    ZonasReducer,
    SensoresReducer
  })

