import React from "react";
import { Grid, Box, Typography } from "@mui/material";

const Footer = () => {
  return (
    <React.Fragment>
      <Grid container style={{ backgroundColor: "#0E56B8", padding: "10px" }}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <Typography style={{ color: "#B6C7E9", textAlign: 'center', fontSize: 12 }}>Powered by ME Elecmetal / v1.1</Typography>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
 
export default Footer;