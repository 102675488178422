import React from "react";
import { Box, Grid, IconButton, Typography, colors } from "@mui/material";
//Iconos
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const SecondBar = ({selected_area,selected_zona,return_to}) => {

  /**Te regresa a la seleccion de areas */
  const go_back_to_areas = () => {
    window.location.href = return_to
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} style={{ backgroundColor: "#356FD1", padding: "10px" }}>
          <Grid container>
            <Grid item>
              <IconButton onClick={go_back_to_areas} style={{ backgroundColor: "#5080E4", color: "#fff", width: "30px", height: "30px" }}><KeyboardArrowLeftIcon /></IconButton>
            </Grid>
            <Grid item style={{ alignContent: "center", padding: "0px 0px 0px 10px" }}>
              <Typography style={{ color: "#fff", fontWeight: "bold" }}>{selected_area}</Typography>
            </Grid>
            <Grid item style={{ alignContent: "center", padding: "0px 0px 0px 10px" }}>
              { selected_zona ? (
                <Box style={{ backgroundColor: "#1D57B8", padding: "5px 10px 8px 10px", textAlign: "center", color: "#fff", borderRadius: "5px" }}>
                  {selected_zona}
                </Box>
              ):(<></>)}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
 
export default SecondBar;