import { HttpRequest } from '../../helpers/HttpRequest';

const Areas = (dict_user_access) => {
  const requestOptions = {
    url: `https://func-utw-prod-001.azurewebsites.net/api/function_get_areas?code=${process.env.REACT_APP_AREAS_TOKEN}&dict_user_access=${dict_user_access}`,
    method: "GET"
  }
  return HttpRequest.request(requestOptions)
}

const Zonas = (area_id) => {
  const requestOptions = {
    url: `https://func-utw-prod-001.azurewebsites.net/api/function_get_thickness_wear_rate_area?code=${process.env.REACT_APP_ZONAS_TOKEN}&id_area=${area_id}`,
    method: "GET"
  }
  return HttpRequest.request(requestOptions)
}

const Sensores = (zona_id) => {
  const requestOptions = {
    url: `https://func-utw-prod-001.azurewebsites.net/api/function_get_devices?code=${process.env.REACT_APP_SENSORES_TOKEN}&id_zone=${zona_id}`,
    method: "GET"
  }
  return HttpRequest.request(requestOptions)
}

export const MainServices = {
  Areas,
  Zonas,
  Sensores
}