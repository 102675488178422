import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
//Icon
import SensorIcon from '../../assets/img/iconos/sensor_trans.png';

const Sensor = ({top,left,name,selected_sensor,hovered,color}) => {

  /**variables */
  const sensor_width = 15;
  const panel_width = name.length*10;
  const left_text_calculation = (sensor_width/2)-(panel_width/2);
  const [selected_color_rgb,set_selected_color_rgb] = useState(`${color.r},${color.g},${color.b}`);
 
  return (
    <React.Fragment>
      <Box id={"sensor_"+name}
        style={{ 
          position: "absolute", 
          top: top, 
          left: left, 
          backgroundColor: selected_sensor ? "#ED4207" : `rgba(${selected_color_rgb},1)`, 
          padding: "5px", 
          borderRadius: "100px", 
          width: "10px", 
          height: "10px", 
          textAlign: "center",
          cursor: "pointer",
          backgroundImage: `url(${SensorIcon})`,
          backgroundSize: "18px 18px",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: "1px 1px"
        }}
      >
        { hovered || selected_sensor ? (
          <>
            {/**Panel Nombre */}
            <Box id={"sensor_"+name}
              style={{ 
                position: "absolute", 
                left: `${left_text_calculation}px`, 
                top: "-30px", 
                width: `${panel_width}px`,
                minWidth: "25px", 
                height: "20px", 
                backgroundColor: selected_sensor ? "#ED4207" : `rgba(${selected_color_rgb},1)`, 
                borderRadius: "100px",
                textAlign: "center",
                cursor: "pointer"
              }}
            >
              <Typography id={"sensor_"+name} style={{ padding: "2px 7px 7px 8px", fontSize: 12, color: "#fff", cursor: "pointer" }}>{name}</Typography>
            </Box>
            <Box 
              id={"sensor_"+name}
              style={{ 
                position: 'absolute', 
                top: "-10px", 
                left: `${(sensor_width/2)-4}px`,
                borderLeft: "5px solid transparent", 
                borderRight: "5px solid transparent", 
                borderTop: `8px solid ${selected_sensor ? "#ED4207" : `rgba(${selected_color_rgb},1)`}` 
              }}
            ></Box>
            {/**Panel Nombre */}
          </>
        ):(<></>)}
      </Box>
    </React.Fragment>
  );
}
 
export default Sensor;