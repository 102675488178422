import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    area_button: {
      backgroundColor: "#0042A0!important", 
      height: "100px", 
      width: "100px", 
      margin: "10px 10px 10px 10px!important", 
      borderRadius: "15px!important",
      color: "#fff!important",
      textTransform: 'none!important',
      fontSize: '11px!important',
      '&:hover': {
        backgroundColor: '#F7451C!important'
      }
    }
})