import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import EnMantencion from "./Views/Common/Mesagges/Mantencion/EnMantencion";
import LoginForm from "./Views/Login/LoginForm";
import RecoverForm from "./Views/Login/RecoverForm";
import PasswordRecover from "./Views/Login/PasswordRecover";
import Areas from "./Views/Areas/Areas";
import Zonas from "./Views/Zonas/Zonas";
import Sensores from "./Views/Sensores/Sensores";

function App() {

  useEffect(() => {
    document.title = 'Monitoreo Remoto de Espesores';
  },[])

  return (
    <React.Fragment>
      <BrowserRouter>
      { localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_ACCESS_TOKEN) ? (
        localStorage.getItem('access_type') !== 'developer' ? (
          localStorage.getItem('system_enabled') === 'true' ? (
            <Routes>
              <Route path="/" element={<Areas />} />
              <Route path="/zonas" element={<Zonas />} />
              <Route path="/sensores" element={<Sensores />} />
            </Routes>
          ):(
            <Routes>
              <Route path="/" element={<EnMantencion />} />
            </Routes>
          )
        ) : (
          <Routes>
            <Route path="/" element={<Areas />} />
            <Route path="/zonas" element={<Zonas />} />
            <Route path="/sensores" element={<Sensores />} />
          </Routes>
        )
        ):(
          <Routes>
            <Route path="/" element={<LoginForm />} />
            <Route path="/recover_form" element={<RecoverForm />} />
            <Route path="/password_reset" element={<PasswordRecover />} />
          </Routes>
        )}
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
