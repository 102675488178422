import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, TextField, Button, Paper } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { useToast } from 'rc-toastr';
import Cookies from 'universal-cookie';
//Imagenes
import LogoIcon from '../../assets/img/Logo ME Tracking.png';
//Icons
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

/**@constant CssTextField Contiene los estilos para los campos de texto */
const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
        borderWidth: "3px",
        height: "50px"
      },
      '&:hover fieldset': {
        borderColor: 'white',
        borderWidth: "3px",
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
        borderWidth: "3px",
      },
    },
  },
})(TextField);

/**@constant cookies Almacena las cookies */
const cookies = new Cookies();

const PasswordRecover = () => {

  /**Variables */
  const { toast } = useToast();
  const [password, set_password] = useState('');
  const [password_confirm, set_password_confirm] = useState('');

  /**Detecta la carga inicial de la pagina para verificar que haya un codigo enviado y tipeado previamente */
  useEffect(() => {
    if (!cookies.get('secret_code')) {
      window.location.replace("/")
    }
  },[])

  /**Ejecuta el cambio de contraseña */
  const change_password = () => {
    if (password !== '' && password_confirm !== '') {
      if (password === password_confirm) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        const urlencoded = new URLSearchParams();
        urlencoded.append("username", cookies.get('email'));
        urlencoded.append("new_password", password_confirm);
        const requestOptions = {method: "POST",headers: myHeaders,body: urlencoded,redirect: "follow"};
        fetch(`https://func-admin-users-prod-001.azurewebsites.net/api/change_password?code=${process.env.REACT_APP_CHANGE_PASSWORD}`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if (result) {
              cookies.remove('secret_code',{ path: '/' })
              cookies.remove('email',{ path: '/' })
              window.location.replace("/")
            }else{
              toast.error("Se ha producido un error al cambiar la contraseña.")
            }
          })
          .catch((error) => console.error(error));
      }else{
        toast.error("Las contraseñas son distintas.")
      }
    }else{
      toast.error("No pueden quedar campos vacios.")
    }
  }

  return (
    <React.Fragment>
      <Grid container style={{ backgroundColor: "#1E3986", height: "100vh" }}>

        <Grid item xl={4} lg={3} md={3} sm={2} xs={0}></Grid>
        <Grid item xl={4} lg={6} md={6} sm={8} xs={12} justifyContent="center">

          <Paper style={{ margin: "30px 0px 30px 0px", backgroundColor: "#2C58BB" }} elevation={4}>
            <Box display="flex" justifyContent="center">

              <Grid container>

              { cookies.get('secret_code') ? (
                <>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                      <div style={{ backgroundColor: "#F7451C", padding: "25px 20px 25px 20px" }}>
                        <div style={{ color: "#fff", width: "35px", height: "12px", border: 'solid 2px', borderRadius: "20px", textAlign: "center" }}>
                          <FiberManualRecordIcon style={{ width: "6px", padding: "0px 1px 0px 1px", color: "#fff", marginTop: "-6px" }} />
                          <FiberManualRecordIcon style={{ width: "6px", padding: "0px 1px 0px 1px", color: "#fff", marginTop: "-6px" }} />
                          <FiberManualRecordIcon style={{ width: "6px", padding: "0px 1px 0px 1px", color: "#fff", marginTop: "-6px" }} />
                        </div>
                      </div>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" style={{ margin: "30px 0px 10px 0px" }}>
                      <img src={LogoIcon} style={{ width: "60%" }}/>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                      <Typography style={{ color: "#fff", fontWeight: 'bold' }}>Cambiar contraseña</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" style={{ margin: "10px 0px 10px 0px" }}>
                      <Typography style={{ color: "#B6C7E9", textAlign: 'center', width: "400px" }}>Ingresa el codigo de recuperación de contraseña que enviamos a tu correo.</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                      <CssTextField label="Nueva Contraseña" type="password" margin="normal" autoComplete='off'
                        inputProps={{ style: { color: 'white' } }}
                        sx={{ width: 350, height: 40, input: { color: 'white' } }}
                        InputLabelProps={{ style: { color: 'white', marginTop: "-4px" } }}
                        onChange={(e) => set_password(e.target.value)}
                      />
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <CssTextField label="Repetir Nueva Contraseña" type="password" margin="normal" autoComplete='off'
                        inputProps={{ style: { color: 'white' } }}
                        sx={{ width: 350, height: 50, input: { color: 'white' } }}
                        InputLabelProps={{ style: { color: 'white', marginTop: "-4px" } }}
                        onChange={(e) => set_password_confirm(e.target.value)}
                      />
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <Box style={{ width: "350px" }}>
                        <Button variant="contained" onClick={() => change_password()} fullWidth style={{ backgroundColor: "#fff", color: "#1D57B8", padding: "18px 50px", textTransform: 'none', fontWeight: 'bold' }}>Cambiar Contraseña</Button>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="13vh">
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                      <FiberManualRecordIcon style={{ width: "10px", padding: "0px 2px 0px 2px", color: "#153884" }} />
                      <FiberManualRecordIcon style={{ width: "10px", padding: "0px 2px 0px 2px", color: "#153884" }} />
                      <FiberManualRecordIcon style={{ width: "12px", padding: "0px 2px 0px 2px", color: "#fff" }} />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                      <Typography style={{ color: "#B6C7E9", textAlign: 'center', fontSize: 12, marginBottom: "10px" }}>Powered by ME Elecmetal / v1.1</Typography>
                    </Box>
                  </Grid>
                </>
              ):(<></>)}

              </Grid>

            </Box>
          </Paper>

        </Grid>

      </Grid>
    </React.Fragment>
  );
}
 
export default PasswordRecover;