import { MainConstants } from "../constants/MainConstants";

const initialState = {
  data: []
}

export function ZonasReducer(state = initialState, action){
  switch (action.type) {
    case MainConstants.ZONAS_SUCCESS:
      return {
        ...state,
        data: action.data
      }
  
    default:
      return state
  }
}