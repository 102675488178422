import React from "react";
import { ThreeCircles } from 'react-loader-spinner';
import { Grid, Typography } from "@mui/material";

const Spinner = ({color,size,fontSize}) => {
  return (
    <React.Fragment>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <ThreeCircles height={size} width={size} radius="9" color={color}/>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ color: color, fontSize: fontSize }}>Cargando...</Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
 
export default Spinner;