import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@material-ui/core";

const MarcadorZona = ({top,left,width,height,name,cur_area,id,selected,color}) => {

  /**variables */
  const top_name_left = (parseInt(width)/2)-((name.length*13)/2);
  const [selected_color_rgb,set_selected_color_rgb] = useState(`${color.r},${color.g},${color.b}`);

  /**Controla cuando clickas una zona */
  const take_me_to = () => {
    window.location.href = '/sensores?sel_area='+cur_area+'&sel_zona='+id
  }

  return (
    <React.Fragment>
      <Box id={"zone_marker_"+id} onClick={take_me_to}
        style={{ 
          position: "absolute", 
          top: top+"px", 
          left: left+"px", 
          width: width+"px", 
          height: height+"px", 
          backgroundColor: selected ? "#21ABFF" : `rgba(${selected_color_rgb},0.7)`, 
          opacity: 0.6, 
          borderRadius: "5px", 
          cursor: "pointer" 
        }}
      ></Box>

      { selected ? (
        <>
        {/**Panel Nombre */}
        <Box id={"zone_marker_"+id}
          style={{ 
            position: "absolute", 
            left: `${left+top_name_left}px`, 
            top: `${top-35}px`, 
            width: `${name.length*13}px`, 
            minWidth: "52px", 
            height: "25px", 
            backgroundColor: selected ? "#21ABFF" : `rgba(${selected_color_rgb},1)`, 
            borderRadius: "5px", 
            textAlign: "center", 
            opacity: 1 
          }}
        >
          <Typography id={"zone_marker_"+id} style={{ padding: "5px 7px 7px 8px", color: "#fff", fontWeight: "bold", fontSize: 11 }}>{name}</Typography>
        </Box>
        <Box style={{ position: 'absolute', left: `${left+(width/2)-5}px`, top: `${top-10}px`, borderLeft: "5px solid transparent", borderRight: "5px solid transparent", borderTop: `8px solid ${selected ? "#21ABFF" : "#1D57B8"}` }}></Box>
        {/**Panel Nombre */}
        </>
      ):(<></>)}
      
    </React.Fragment>
  );
}
 
export default MarcadorZona;