import React, { useEffect, useState, useRef, Suspense } from "react";
import { Grid, Box, Tabs, Tab, Divider, Typography } from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import MainBar from "../Common/MainBar";
import SecondBar from "../Common/SecondBar";
import MapTab from "../Common/Tabs/MapTab";
import MapTabSelected from "../Common/Tabs/MapTabSelected";
import MarcadorZona from "./MarcadorZona";
import * as gen_func from '../../helpers/general_functions';
import { MainActions } from "../../redux/actions/MainActions";
import Spinner from "../Common/Spinner";
import Footer from "../Common/Footer";

const Zonas = () => {

  /**variables */
  const panel = useRef();
  const topPanel = useRef();
  const dispatch = useDispatch();
  const [real_width,set_real_width] = useState(0);
  const [real_height,set_real_height] = useState(0);
  const [main_width,set_main_width] = useState(0);
  const [main_height,set_main_height] = useState(0);
  const [resize,set_resize] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const selected_area = queryParameters.get("sel_area");
  const [area_data,set_area_data] = useState([]);
  const [zona_data,set_zona_data] = useState([]);
  const [disponibilidad,set_disponibilidad] = useState([]);
  const [value, setValue] = useState(0);
  const [cur_area,set_cur_area] = useState(selected_area);
  const [area_name,set_area_name] = useState('');
  const [selected_hover,set_selected_hover] = useState(null);
  const areas = useSelector(state => { return state.AreasReducer.data })
  const zonas = useSelector(state => { return state.ZonasReducer.data })

  /**Primera carga */
  useEffect(() => {
    var faena_id = JSON.stringify(JSON.parse(localStorage.getItem('faena_id')))
    var pais_id = JSON.stringify(JSON.parse(localStorage.getItem('pais_id')))
    var operador_id = JSON.stringify(JSON.parse(localStorage.getItem('operador_id')))
    dispatch(MainActions.getAreas(`{'pais_id': ${pais_id}, 'operador_id': ${operador_id}, 'id': ${faena_id}}`))
    dispatch(MainActions.getZonas(selected_area))
    document.addEventListener('mousemove', function(evt) {
      if (!evt.target.id.includes("zone_marker_")) {
        set_selected_hover(null)
      }else{
        set_selected_hover(evt.target.id.replace("zone_marker_",""))
      }
    })
    window.addEventListener('resize', function(evt) {
      set_resize(true)
    })
    window.addEventListener("resize",gen_func.debounce(function(e){
      this.window.location.reload()
      set_resize(false)
    }))
  },[])

  /**Areas */
  useEffect(() => {
    if (areas.length !== 0) {
      set_area_data(areas)
      areas.forEach((area,index) => {
        if (area.id_area === parseInt(selected_area)) {
          setValue(index)
          set_area_name(gen_func.format_area_text(area.name_area))
        }
      })
    }
  },[areas])

  /**Zonas */
  useEffect(() => {
    if (zonas.length !== 0) {
      set_zona_data(zonas.data_zones)
      set_disponibilidad(zonas.data_area)
    }
  },[zonas])

  /**Controla la distribucion de las zonas */
  const get_image_dimentions = () => {
    const img = new Image();
    img.onload = function() {
      let width = panel.current.clientWidth
      let height = (panel.current.clientWidth * this.height)/this.width
      set_main_width(width)
      set_main_height(height)
      set_real_width(this.width)
      set_real_height(this.height)
    }
    img.src = `https://${process.env.REACT_APP_STOGARE_ACCOUNT}.blob.core.windows.net/${process.env.REACT_APP_CONTAINER_AREAS}/${zonas.data_area.img_file_name}`
  }

  /**
   * Controla el diseño de los Tabs
   * @param {Object} props 
   * @returns Componente Tab Independiente
   */
  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && (<Box sx={{ backgroundColor: "#F8F8F8", padding: "1px 8px 8px 8px" }}>{children}</Box>)}
      </div>
    );
  }

  /**
   * Controla el cambio de los Tabs
   * @param {Object} event 
   * @param {String} newValue 
   */
  const handleChange = (event, newValue) => {
    set_area_name(gen_func.format_area_text(area_data[newValue].name_area))
    area_data.forEach(element => {
      if (parseInt(area_data[newValue].id_area) === parseInt(element.id_area)) {
        dispatch(MainActions.getZonas(element.id_area))
        set_cur_area(element.id_area)
      }
    })
    setValue(newValue) 
  }

  /**
   * Controla el Tab que se selecciona
   * @param {Int} index 
   * @returns El Tab seleccionado
   */
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  /**
   * Me lleva a la zona especificada
   * @param {Int} zona_id 
   */
  const take_me_to = (zona_id) => {
    window.location.href = '/sensores?sel_area='+selected_area+'&sel_zona='+zona_id
  }

  /**
   * Renderiza los componentes dependiendo de las areas existentes
   * @returns Componente Tab
   */
  const render_tabs = () => {
    return area_data.map((area,index) => {
      let name = gen_func.format_area_text(area.name_area)
      return <Tab key={index} style={{ minHeight: "30px", height: "30px"  }} label={value === index ? (<MapTabSelected name={name} />):(<MapTab name={name} />)} {...a11yProps(index)}/>
    })
  }

  /**
   * Renderiza los mapas de las areas
   * @returns Componente CustomTab
   */
  const render_tab_panels = () => {
    return area_data.map((area,index) => {
      get_image_dimentions()
      return (
        <CustomTabPanel value={value} index={index}>
          <Grid container>

            {/**MAPA */}
            <Grid item xs={8} ref={panel} style={{ padding: "10px 0px 10px 10px" }}>
              <Suspense fallback={null}>
                <img src={`https://${process.env.REACT_APP_STOGARE_ACCOUNT}.blob.core.windows.net/${process.env.REACT_APP_CONTAINER_AREAS}/${zonas.data_area.img_file_name}`} style={{ width: "100%" }} />
                {render_zona_marcada()}
              </Suspense>
            </Grid>
            {/**MAPA */}
            
            <Grid item xs={4}>

              {/**TABLA ESPESORES */}
              <Box style={{ backgroundColor: "#fff", margin: "35px 5px 10px 10px" }}>
                <Grid container style={{ padding: "10px" }}>
                  <Grid item xs={12} style={{ justifyContent: 'center', alignContent: 'center', display: "flex", paddingBottom: "5px" }}>
                    <Typography style={{ color: "#0057BB", fontWeight: "bold", fontSize: 15 }}>Espesor</Typography>
                  </Grid>
                  <Divider style={{ width: "100%" }} />
                  <Grid item xs={12} style={{ justifyContent: 'center', alignContent: 'center', width: "100%", display: "flex" }}>

                    <Box style={{ width: "25%", textAlign: "center", padding: "2px" }}>
                      <Typography style={{ fontWeight: "bold", fontSize: 12, color: "#5B5B5B" }}>Zona</Typography>
                    </Box>
                    <Box style={{ width: "30%", textAlign: "center", padding: "2px" }}>
                      <Typography style={{ fontWeight: "bold", fontSize: 12, color: "#5B5B5B" }}>Minimo</Typography>
                    </Box>
                    <Box style={{ width: "30%", textAlign: "center", padding: "2px" }}>
                      <Typography style={{ fontWeight: "bold", fontSize: 12, color: "#5B5B5B" }}>Promedio</Typography>
                    </Box>
                    <Box style={{ width: "30%", textAlign: "center", padding: "2px" }}>
                      <Typography style={{ fontWeight: "bold", fontSize: 12, color: "#5B5B5B" }}>Maximo</Typography>
                    </Box>

                  </Grid>
                  <Divider style={{ width: "100%" }} />
                  {render_espesores()}
                </Grid>
              </Box>
              {/**TABLA ESPESORES */}

              {/**TABLA TASA DESGASTE */}
              <Box style={{ backgroundColor: "#fff", margin: "15px 5px 10px 10px" }}>
                <Grid container style={{ padding: "10px" }}>
                  <Grid item xs={12} style={{ justifyContent: 'center', alignContent: 'center', display: "flex", paddingBottom: "5px" }}>
                    <Typography style={{ color: "#0057BB", fontWeight: "bold", fontSize: 15 }}>Tasa de desgaste</Typography>
                  </Grid>
                  <Divider style={{ width: "100%" }} />
                  <Grid item xs={12} style={{ justifyContent: 'center', alignContent: 'center', width: "100%", display: "flex" }}>

                    <Box style={{ width: "25%", textAlign: "center", padding: "2px" }}>
                      <Typography style={{ fontWeight: "bold", fontSize: 12, color: "#5B5B5B" }}>Zona</Typography>
                    </Box>
                    <Box style={{ width: "30%", textAlign: "center", padding: "2px" }}>
                      <Typography style={{ fontWeight: "bold", fontSize: 12, color: "#5B5B5B" }}>Minimo</Typography>
                    </Box>
                    <Box style={{ width: "30%", textAlign: "center", padding: "2px" }}>
                      <Typography style={{ fontWeight: "bold", fontSize: 12, color: "#5B5B5B" }}>Promedio</Typography>
                    </Box>
                    <Box style={{ width: "30%", textAlign: "center", padding: "2px" }}>
                      <Typography style={{ fontWeight: "bold", fontSize: 12, color: "#5B5B5B" }}>Maximo</Typography>
                    </Box>

                  </Grid>
                  <Divider style={{ width: "100%" }} />
                  {render_tasa_desgaste()}
                </Grid>
              </Box>
              {/**TABLA TASA DESGASTE */}

              {/**TABLA DISPONIBILIDAD */}
              <Box style={{ backgroundColor: "#fff", margin: "15px 5px 10px 10px" }}>
                <Grid container style={{ padding: "10px" }}>
                  <Grid item xs={12} style={{ justifyContent: 'center', alignContent: 'center', width: "100%", display: "flex" }}>
                    <Box style={{ width: "30%", textAlign: "left", padding: "2px" }}>
                      <Typography style={{ color: "#0057BB", fontWeight: "bold", fontSize: 15 }}>Uptime de Area</Typography>
                    </Box>
                    <Box style={{ width: "90%", backgroundColor: "#F8F8F8", textAlign: "center", alignContent: "center" }}>
                      { zona_data[0].coords !== null ? (
                        <Typography style={{ color: "#0057BB", fontWeight: "bold" }}>{disponibilidad.availability}%</Typography>
                      ):(<>-</>)}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/**TABLA DISPONIBILIDAD */}

            </Grid>
          </Grid>
        </CustomTabPanel>
      )
    })
  }

  /**
   * Renderiza las zonas marcadas
   * @returns Componente marcador zona
   */
  const render_zona_marcada = () => {
    if (zona_data[0].coords !== null) {
      return zona_data.map((zone) => {
        if(real_width !== 0){
  
          let t = parseInt(zone.coords.top)//top base de datos
          let l = parseInt(zone.coords.left)//left base de datos
          let h = parseInt(zone.coords.width)//height base de datos
          let w = parseInt(zone.coords.height)//width base de datos
          let top_plus = topPanel.current.clientHeight+50//Pixeles de barras superiores + tabs
  
          let top = Math.round((t*main_height)/real_height)+top_plus//top base de datos * height de imagen en pantalla / height de imagen real + top_plus
          let left = Math.round((l*main_width)/real_width)+25//left base de datos * width de imagen en pantalla / width de imagen real + padding lateral
          let width = Math.round((h*main_height)/real_height)//height base de datos * height de imagen en pantalla / height de imagen real
          let height = Math.round((w*main_width)/real_width)//width base de datos * width de imagen en pantalla / width de imagen real
          
          let selected = parseInt(selected_hover) === parseInt(zone.id_zone) ? true : false
          let color = zone.markers_color ? zone.markers_color : {r:69,g:172,b:255}
          
          return <Box key={zone.id_zone}> 
            <MarcadorZona top={top} left={left} width={width} height={height} name={zone.name_zone} id={zone.id_zone} selected={selected} cur_area={cur_area} color={color} />
          </Box>
        }
      })
    }
  }

  /**
   * Renderiza los datos de los espesores
   * @returns Componente datos espesores
   */
  const render_espesores = () => {
    if (zona_data[0].coords !== null) {
      return zona_data.map((zone) => {
        return(
        <Grid key={zone.id_zone} id={"zone_marker_"+zone.id_zone} onClick={() => take_me_to(zone.id_zone)} item xs={12} style={{ justifyContent: 'center', alignContent: 'center', width: "100%", display: "flex", cursor: "pointer" }}>
  
          <Box id={"zone_marker_"+zone.id_zone} style={{ width: "25%", backgroundColor: parseInt(selected_hover) === parseInt(zone.id_zone) ? "#21ABFF" : "#D1D1D1", margin: "5px 2px 5px 0px", borderRadius: "3px", textAlign: "center", padding: "3px 0px 3px 0px" }}>
            <Typography id={"zone_marker_"+zone.id_zone} style={{ color: parseInt(selected_hover) === parseInt(zone.id_zone) ? "#fff" : "#919191", fontWeight: "bold" }}>{zone.name_zone}</Typography>
          </Box>
          <Box id={"zone_marker_"+zone.id_zone} style={{ width: "30%", backgroundColor: parseInt(selected_hover) === parseInt(zone.id_zone) ? "#E9E9E9" : "#F8F8F8", margin: "5px 2px 5px 2px", borderRadius: "3px", textAlign: "center", padding: "3px 0px 3px 0px" }}>
            <Typography id={"zone_marker_"+zone.id_zone} style={{ color: "#0057BB", fontWeight: "bold" }}>{gen_func.check_if_NaN(parseFloat(zone.thickness.min).toFixed(1)+" mm")}</Typography>
          </Box>
          <Box id={"zone_marker_"+zone.id_zone} style={{ width: "30%", backgroundColor: parseInt(selected_hover) === parseInt(zone.id_zone) ? "#E9E9E9" : "#F8F8F8", margin: "5px 2px 5px 2px", borderRadius: "3px", textAlign: "center", padding: "3px 0px 3px 0px" }}>
            <Typography id={"zone_marker_"+zone.id_zone} style={{ color: "#0057BB", fontWeight: "bold" }}>{gen_func.check_if_NaN(parseFloat(zone.thickness.mean).toFixed(1)+" mm")}</Typography>
          </Box>
          <Box id={"zone_marker_"+zone.id_zone} style={{ width: "30%", backgroundColor: parseInt(selected_hover) === parseInt(zone.id_zone) ? "#E9E9E9" : "#F8F8F8", margin: "5px 0px 5px 2px", borderRadius: "3px", textAlign: "center", padding: "3px 0px 3px 0px" }}>
            <Typography id={"zone_marker_"+zone.id_zone} style={{ color: "#0057BB", fontWeight: "bold" }}>{gen_func.check_if_NaN(parseFloat(zone.thickness.max).toFixed(1)+" mm")}</Typography>
          </Box>
  
        </Grid>)
      })
    }
  }

  /**
   * Renderiza los datos de las tasa de desgaste
   * @returns Componente datos tasa desgaste
   */
  const render_tasa_desgaste = () => {
    if (zona_data[0].coords !== null) {
      return zona_data.map((zone) => {
        return(
        <Grid id={"zone_marker_"+zone.id_zone} onClick={() => take_me_to(zone.id_zone)} item xs={12} style={{ justifyContent: 'center', alignContent: 'center', width: "100%", display: "flex", cursor: "pointer" }}>

          <Box id={"zone_marker_"+zone.id_zone} style={{ width: "25%", backgroundColor: parseInt(selected_hover) === parseInt(zone.id_zone) ? "#21ABFF" : "#D1D1D1", margin: "5px 2px 5px 0px", borderRadius: "3px", textAlign: "center", padding: "3px 0px 3px 0px" }}>
            <Typography id={"zone_marker_"+zone.id_zone} style={{ color: parseInt(selected_hover) === parseInt(zone.id_zone) ? "#fff" : "#919191", fontWeight: "bold" }}>{zone.name_zone}</Typography>
          </Box>
          <Box id={"zone_marker_"+zone.id_zone} style={{ width: "30%", backgroundColor: parseInt(selected_hover) === parseInt(zone.id_zone) ? "#E9E9E9" : "#F8F8F8", margin: "5px 2px 5px 2px", borderRadius: "3px", textAlign: "center", padding: "3px 0px 3px 0px" }}>
            <Typography id={"zone_marker_"+zone.id_zone} style={{ color: "#0057BB", fontWeight: "bold" }}>{gen_func.check_if_NaN(parseFloat(zone.wear_rate.min*30).toFixed(3)+"  mm/mes")}</Typography>
          </Box>
          <Box id={"zone_marker_"+zone.id_zone} style={{ width: "30%", backgroundColor: parseInt(selected_hover) === parseInt(zone.id_zone) ? "#E9E9E9" : "#F8F8F8", margin: "5px 2px 5px 2px", borderRadius: "3px", textAlign: "center", padding: "3px 0px 3px 0px" }}>
            <Typography id={"zone_marker_"+zone.id_zone} style={{ color: "#0057BB", fontWeight: "bold" }}>{gen_func.check_if_NaN(parseFloat(zone.wear_rate.mean*30).toFixed(3)+" mm/mes")}</Typography>
          </Box>
          <Box id={"zone_marker_"+zone.id_zone} style={{ width: "30%", backgroundColor: parseInt(selected_hover) === parseInt(zone.id_zone) ? "#E9E9E9" : "#F8F8F8", margin: "5px 0px 5px 2px", borderRadius: "3px", textAlign: "center", padding: "3px 0px 3px 0px" }}>
            <Typography id={"zone_marker_"+zone.id_zone} style={{ color: "#0057BB", fontWeight: "bold" }}>{gen_func.check_if_NaN(parseFloat(zone.wear_rate.max*30).toFixed(3)+" mm/mes")}</Typography>
          </Box>

        </Grid>)
      })
    }
  }

  return (
    <React.Fragment>

      <Grid container direction="column" style={{ minHeight: "100vh" }}>
        <Grid item ref={topPanel} style={{ flexGrow: 0 }}>
          <MainBar />
          <SecondBar selected_area={area_name} return_to={'/'} />
        </Grid>

        <Grid item container style={{ flexGrow: 1 }}>
          {resize ? (
            <Grid item xs={12} style={{ textAlign: "center", padding: "40px" }}>
              <Spinner color="#2C58BB" size="200" fontSize={30} />
            </Grid>
          ) : zonas.length !== 0 ? (
            <Grid item xs={12} style={{ padding: "10px" }}>
              <Box sx={{ width: '100%' }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  style={{ minHeight: "30px", height: "30px" }}
                  TabIndicatorProps={{ style: { display: "none" } }}
                >
                  {render_tabs()}
                </Tabs>
                {render_tab_panels()}
              </Box>
            </Grid>
          ) : (
            <Grid item xs={12} style={{ textAlign: "center", padding: "40px" }}>
              <Spinner color="#2C58BB" size="200" fontSize={30} />
            </Grid>
          )}
        </Grid>

        <Grid item style={{ flexGrow: 0 }}>
          <Footer />
        </Grid>
      </Grid>

    </React.Fragment>
  );
}
 
export default Zonas;